<template>
    <Table
        ref="table"
        :isLoading="isLoading"
        :isRecursive="true"
        :columnConfig="tableColumnConfig"
        :toolbox="['columnSetting', 'excel', 'zooming']"
        :isUseCard="false"
        :isFocusRow="true"
        :descriptions="descriptions"
        :rows="tableShowData"
        :sortDefault="{ sort: 'desc', sortBy: 'applyDate' }"
        :isFixedHeader="true"
        :excelConfig="excelConfig"
        :hiddenColumnDefault="['crossMentorName']"
    >
        <template v-slot:salesName="{ isHeader, row }">
            <div v-if="!isHeader" v-html="row.salesName" />
        </template>
        <template v-slot:license="{ isHeader, row }">
            <div
                v-if="!isHeader && typeof row.license === 'object'"
                class="license-icon"
            >
                <div
                    class="life-license-icon"
                    :class="row.license?.life ? '' : 'disabled'"
                />
                <div
                    class="property-license-icon"
                    :class="row.license?.property ? '' : 'disabled'"
                />
                <div
                    class="investment-license-icon"
                    :class="row.license?.investment ? '' : 'disabled'"
                />
                <div
                    class="currency-license-icon"
                    :class="row.license?.foreignCurrency ? '' : 'disabled'"
                />
            </div>
        </template>
    </Table>
</template>
<script>
import Table from '@/components/Table.vue'
import {
    manpowerColumn,
    manpowerDescriptions,
    manpowerExcelDescriptions
} from '@/assets/javascripts/manpower/setting'
import _ from 'lodash'

export default {
    name: 'ManpowerTable',
    components: { Table },
    props: {
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        licenseList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        stylerList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        salesTargetList: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        transformDetail: function (rows) {
            return rows.reduce((memo, row) => {
                const uid = row.uid
                const style = this.stylerList[uid]
                const manCode = row.manCode
                const license = this.licenseList[manCode]
                const salesTarget = this.salesTargetList[manCode]

                if (row.sub) {
                    if (style?.visible === false) {
                        memo = [...memo, ...this.transformDetail(row.sub)]
                    } else {
                        row._detail = this.transformDetail(row.sub)
                        this.getSumRow(row, row._detail)
                        memo.push(_.omit(row, 'sub'))
                    }
                } else {
                    if (style?.visible === false) {
                        return memo
                    }
                    row.salesName = row.name || '-'
                    row.isMonthlyActualManpower = salesTarget?.hasReachedMonthly
                        ? '是'
                        : '否'
                    row.isYearlyActualManpower = salesTarget?.hasReachedYearly
                        ? '是'
                        : '否'
                    row.apStuName = row.apStuName || '-'

                    const licenseDefault = {
                        life: false,
                        property: false,
                        investment: false,
                        foreignCurrency: false
                    }
                    row.license = license || licenseDefault
                    row.licenseTrans = license?.life ? '壽' : ''
                    row.licenseTrans += license?.property ? '產' : ''
                    row.licenseTrans += license?.investment ? '投' : ''
                    row.licenseTrans += license?.foreignCurrency ? '外' : ''
                    row.propertyRegisterDate =
                        row.propertyRegisterDate?.substring(0, 10) || '-'
                    row.lifeRegisterDate =
                        row.lifeRegisterDate?.substring(0, 10) || '-'
                    row.applyDate = row.applyDate?.substring(0, 10) || '-'
                    row.orgBmName = row.parentName || '-'
                    row.orgBmGroupName = row.orgBm || '-'

                    memo.push(row)
                }
                return memo
            }, [])
        },
        getSumRow: function (row, detail) {
            Object.keys(this.tableColumnConfig).forEach((key) => {
                if (this.tableColumnConfig[key].position === 'right') {
                    row[key] = _.sumBy(detail, (o) => {
                        if (typeof o[key] !== 'number') return 0
                        else return o[key]
                    })
                }
            })
            // salesName 用於轉過 HTML 的欄位
            row.salesName = `${row.name}&nbsp;&nbsp;&nbsp;${row.groupTitle}`
            // belows are for Excel,
            row.apStuName = '-'
            row.licenseTrans = '-'
            row.titleTrans = '-'
            row.propertyRegisterDate = '-'
            row.lifeRegisterDate = '-'
            row.bgName = '-'
            row.applyDate = '-'
            row.orgBmName = '-'
            row.orgBmGroupName = '-'

            return row
        },
        checkEmptyRow: function (rows) {
            return rows.reduce((memo, row) => {
                if (!('_detail' in row)) {
                    memo.push(row)
                } else if (row._detail.length === 0) {
                    memo.push({
                        ...row,
                        _detail: [
                            {
                                isEmptyRow: true,
                                emptyHint: '無符合條件人員',
                                rowWidth: `${this.tableVW}px`
                            }
                        ]
                    })
                } else {
                    memo.push({
                        ...row,
                        _detail: this.checkEmptyRow(row._detail)
                    })
                }
                return memo
            }, [])
        },
        getTableVW: function () {
            this.tableVW = this.$refs.table.$el.getBoundingClientRect().width
        }
    },
    computed: {
        tableShowData: function () {
            if (this.listData.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.checkEmptyRow(this.transformDetail(dataset))
            return dataset
        },
        excelFileName: function () {
            const name =
                this.filterLog.bgCode?.label || this.filterLog.manCode?.label
            return `人力查詢_${name}`
        },
        excelConfig: function () {
            return {
                fileName: this.excelFileName,
                filters: this.filterLog,
                descriptions: this.excelDescriptions
            }
        }
    },
    data() {
        return {
            tableColumnConfig: manpowerColumn,
            descriptions: manpowerDescriptions,
            excelDescriptions: manpowerExcelDescriptions,
            tableVW: 0
        }
    },
    mounted() {
        this.getTableVW()
        window.addEventListener('resize', this.getTableVW)
    },
    unmounted() {
        window.removeEventListener('resize', this.getTableVW)
    }
}
</script>
<style lang="scss" scoped>
:deep(.table) {
    @for $i from 0 through 15 {
        .deep-#{$i} .sales-name {
            padding-left: 15px + 15px * $i;
        }
    }
}

:deep(.license-icon) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    div {
        display: inline-block;
        vertical-align: -2px;
        width: 15px;
        height: 15px;
        margin-right: 2px;
        &:last-of-type {
            margin-right: 0;
        }
    }
    &.description {
        display: block;
        div {
            margin-right: 5px;
        }
    }
}
</style>
