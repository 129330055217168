const manpowerFilterTitles = {
    relationMode: '組織種類',
    tLevelName: '職級',
    baseDate: '基準日',
    _seq: ['relationMode', 'tLevelName', 'baseDate']
}

const manpowerColumn = {
    salesName: {
        label: '業務員姓名',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'left',
        widthPx: 180,
        width: 4,
        isUnhide: true,
        isUnmovable: true
    },
    isMonthlyActualManpower: {
        label: '月實動人力',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 125,
        width: 4,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '月受理業績達2萬C',
                mobile: null
            }
        }
    },
    isYearlyActualManpower: {
        label: '年實動人力',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 125,
        width: 4,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '年受理業績達24萬C',
                mobile: null
            }
        }
    },
    apStuName: {
        label: '介紹人',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 4
    },
    license: {
        label: '證照',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 4,
        alias: {
            licenseTrans: {
                label: '具備證照',
                position: 'center'
            }
        }
    },
    titleTrans: {
        label: '職級代號',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 80,
        width: 4
    },
    propertyRegisterDate: {
        label: '產險登錄日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        widthPx: 100,
        width: 4
    },
    lifeRegisterDate: {
        label: '壽險登錄日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        widthPx: 100,
        width: 4
    },
    applyDate: {
        label: '報聘日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        widthPx: 100,
        width: 4
    },
    orgBmName: {
        label: '部主管',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 4
    },
    bgName: {
        label: '營業單位',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 4
    },
    orgBmGroupName: {
        label: '事業部',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 140,
        width: 4
    },
    mentorName: {
        label: '輔導人',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 4
    },
    recommenderName: {
        label: '推薦人',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 4
    },
    crossRecommenderName: {
        label: '跨區推薦人',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 4
    },
    crossMentorName: {
        label: '跨區育成人',
        sortEnabled: false,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 100,
        width: 4
    }
}

const manpowerDescriptions = [
    `<div class="license-icon description">
        <div class="life-license-icon"></div>為壽險證照、
        <div class="property-license-icon"></div>為產險證照、
        <div class="currency-license-icon"></div>為外幣證照、
        <div class="investment-license-icon"></div>為投資型證照。
    </div>`
]

const manpowerExcelDescriptions = [
    '"壽"為壽險證照、"產"為產險證照、"外"為外幣證照、"投"為投資型證照。'
]

export {
    manpowerFilterTitles,
    manpowerColumn,
    manpowerDescriptions,
    manpowerExcelDescriptions
}
